.file-drop-input {
    position: relative;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid #ced4da;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
    }

    &__plus {
        font-size: 3vw;
        color: #0d6efd;
    }

    &__text {
        text-align: center;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &--active {
            color: #0d6efd;
        }
    }

    &__error {
        font-size: 12px;
        color: #f5222d;
        position: absolute;
        bottom: -20px;
        left: 10px;
        z-index: 1;
    }

    &__preview {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            height: 50%;
        }

        a {
            padding: 10px;
        }
    }
}
