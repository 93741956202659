input, select {
  outline: none;
}

.input {
  position: relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &__field {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;

    &--password {
      width: 20px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &:disabled {
      background-color: #f5f5f5;
    }
  }

  &__error {
    font-size: 12px;
    color: #f5222d;
    position: absolute;
    bottom: -20px;
    left: 10px;
    z-index: 1;
  }

  &--password {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    .input__field {
      border: none;
      width: calc(100% - 20px);
    }
  }

  &--phone {
    display: flex;

    .input__field--select {
      border-radius: 0.25rem 0 0 0.25rem;
      width: 50px;
    }

    .input__field--input {
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
}
