.payment-modal {
    transition: max-height 0.3s ease-out;

    &__commision {
        font-size: 12px;
        color: #8c8c8c;
        display: block;

        &--amount {
            font-size: 14px;
            color: #000;
        }
    }
}
