.card_of {
    &_service {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__name {
            font-size: 200%;
            color: #001f4d;
            font-weight: 400;
        }

        &__description {
            font-size: 16px;
            color: #8a92a6;
            margin: 16px 0 33px;
        }

        &__info {
            font-size: 16px;
            color: #8a92a6;
            margin: 0 0 12px 12px;
        }

        &__info_block {
            text-align: left;
        }

        &__locations {
            display: flex;
            align-items: center;

            .card_of_service__info {
                margin-bottom: 0;
            }
        }
    }

    &_location {
        .card {
            height: calc(100% - 1.5rem);
        }

        &__image {
            width: 100%;
            border-radius: 18px;
        }

        &__name {
            margin: 18px 0;
            font-size: 150%;
            color: #001f4d;
        }

        &__description {
            font-size: 16px;
            color: #8a92a6;
            min-height: 72px;
        }

        &__info_block {
            text-align: left;
            margin-bottom: 22px;
        }

        &__list {
            display: grid;
            flex-direction: row;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 3vw;
        }

        .btn {
            margin: 0 auto;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &_order {
        height: 216px;

        &__info {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__content {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__img {
            width: 25%;
            object-fit: cover;
            border-radius: 16px;
            margin-right: 2vw;
        }

        &__locations {
            margin: 10px 0 20px;
            img {
                margin-right: 10px;
            }
        }

        &__description {
            font-size: 16px;
            color: #8a92a6;
            max-width: 80%;
            margin-bottom: 0;
        }

        &__extrainfo {
            display: flex;
            align-items: center;

            div {
                margin-right: 3vw;
            }
        }

        &__price {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 34px;
            }
        }
    }
}

.filters {
    &__delimiter {
        border-right: 1px solid #eeeeee;

        &:last-child {
            border-right: none;
        }
    }
}

.checkout {
    &__img {
        width: 100%;
        margin-bottom: 35px;
        border-radius: 18px;
    }

    &__main_info {
        display: flex;
        justify-content: space-between;
    }

    &__price {
        font-size: 32px;
    }

    &__extrainfo {
        display: flex;
        align-items: center;

        div {
            margin-right: 3vw;
        }
    }
    &__icon {
        margin-right: 10px;
    }

    &__locations {
        margin: 10px 0;
    }

    &__description {
        font-size: 16px;
        color: #8a92a6;
        border-top: 1px solid #eeeeee;
        padding-top: 20px;
        margin-top: 25px;
    }
}
