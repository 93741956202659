.field-with-validation {
    &__checkbox {
        label {
            margin: 0 0 0 10px;
            order: 1;
        }

        .field-with-validation__block {
            display: flex;
            align-items: center;
        }
    }
}