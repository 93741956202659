.payment-block {
    &__name {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #eeeeee;
    }

    &__services {
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
    }

    &__service {
        width: 20%;

        img {
            width: 100%;
            height: 50px;
        }
    }

    &__description {
        margin: 30px 0;
    }

    &__buttons {
        margin-top: 40px;

        .btn {
            margin-right: 20px;
        }
    }

    &__btn--back {
        svg {
            margin-right: 5px;
            margin-top: -4px;
        }
    }
}
